import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade"; // Import fade effect style
import { Autoplay, EffectFade, Pagination } from "swiper/modules";

const BannerSlider = () => {
  return (
    <Swiper
      effect="fade" // Set the effect to "fade"
      grabCursor={true}
      pagination={true}
      modules={[EffectFade, Pagination, Autoplay]}
      className="mySwiper"
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      <SwiperSlide>
        <img src="/images/banner/img-1.png" alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/banner/img-2.jpeg" alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/banner/img-3.jpeg" alt="" />
      </SwiperSlide>
    </Swiper>
  );
};

export default BannerSlider;
