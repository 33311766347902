import React, { useEffect } from "react";
import OurServices from "./OurServices";
import Contact from "./Contact";
import Philosophy from "./Philosophy";
import Typewriter from "../Common/Typewriter";
import AOS from "aos";
import "aos/dist/aos.css";
import BannerSlider from "./BannerSlider";
import Achievement from "./Achievement";
import SuccessStories from "./SuccessStories";

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div
        id="home"
        className="banner-bg lg:h-screen w-full sm:bg-center grid lg:grid-cols-2 grid-cols-1 justify-items-center xl:gap-10 2xl:pt-0 lg:pt-12 pt-36 lg:pb-0 pb-20"
      >
        <div className="w-full 2xl:container mx-auto xl:px-16 md:px-8 px-5 flex flex-col justify-center lg:mt-0 mt-12">
          <h1
            data-aos="fade-right"
            className="font-montserrat xl:text-5xl text-3xl xl:font-medium font-semibold text-white"
          >
            Welcome to Algorizmi
          </h1>
          <p
            data-aos="fade-right"
            className="font-montserrat text-white 2xl:text-2xl xl:text-lg sm:text-base text-sm font-medium mt-4 h-8"
          >
            <Typewriter
              textArray={[
                "Unlocking Insights via Surveys",
                "Informed Choices through Data Analysis",
                "Enhanced Decision-Making via Survey Results",
              ]}
              // period={50}
              // delay={2000}
            />
          </p>
          <div
            data-aos="fade-right"
            className="mt-2 w-44 h-[8px] rounded-lg bg-[#F10062]"
          ></div>

          <p
            data-aos="fade-right"
            className="font-montserrat text-white sm:text-xl text-base font-light mt-6"
          >
            At Algorizmi, we understand the power of insights. Our mission is
            simple: to empower businesses with the knowledge they need to
            thrive. Similar to the approach of renowned survey experts, we
            specialize in the art of collecting and analyzing data to uncover
            trends, patterns, and valuable insights.
          </p>
        </div>
        <div className="w-full flex items-center justify-center lg:order-last order-first">
          <div data-aos="fade-right" className="overflow-hidden w-4/5 md:h-96">
            <BannerSlider />
          </div>
        </div>
      </div>
      <Philosophy />
      <OurServices />
      <Achievement />
      <SuccessStories />
      <div className="contact-bg"></div>
      <div id="fmcg" className="it-service py-10">
        <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 xl:w-[85%] h-full flex flex-col items-center justify-center">
          <h3 data-aos="fade-right" className="text-white font-raleway lg:text-4xl sm:text-2xl text-xl font-semibold sm:text-start text-center">
            Serving the
            <span className="text-[#26E0E7]"> FMCG Sector</span>{" "}
          </h3>
          <p data-aos="fade-right" className="font-montserrat sm:text-xl text-base sm:font-medium font-normal text-white text-center mt-3">
            From household items to personal care products, Algorizmi
            specializes in gathering insights for the Fast-Moving Consumer Goods
            (FMCG) industry
          </p>
        </div>
      </div>
      <Contact />
    </>
  );
};

export default Home;
