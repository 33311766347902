const Footer = () => {
  return (
    <>
      <div className="footer-bg">
        <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-5 xl:w-[85%] flex items-center justify-between">
          <a href="/">
            {" "}
            <img src="/logo.png" alt="" className="w-14" />
          </a>
          <p className="font-raleway text-white font-medium text-sm">
            Algorizmi © 2024 / All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
