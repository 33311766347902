import React from "react";

const Contact: React.FC = () => {
  return (
    <div id="contact">
      <div className="bg-[#F6F6F7] z-10">
        <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-16 xl:w-[85%] grid md:grid-cols-2 grid-cols-1 xl:gap-28 lg:gap-16 gap-10">
          <form className="sm:order-first order-last">
            <div data-aos="fade-right">
              <input
                required
                type="text"
                name="user_name"
                className="w-full bg-transparent border border-primary h-12 px-4 font-raleway text-xl text-[#3b3b3b] placeholder:text-[#3b3b3b]  rounded-md"
                placeholder="Name*"
              />
            </div>
            <div data-aos="fade-right">
              <input
                required
                type="email"
                name="user_email"
                className="w-full bg-transparent border border-primary h-12 px-4 font-raleway text-xl text-[#3b3b3b] placeholder:text-[#3b3b3b]  rounded-md mt-5"
                placeholder="Email*"
              />
            </div>
            <textarea
              required
              data-aos="fade-right"
              name="user_description"
              className="w-full bg-transparent border border-primary p-4 font-raleway text-xl text-[#3b3b3b] placeholder:text-[#3b3b3b] mt-5 rounded-md"
              rows={7}
              placeholder="Please describe what you need.*"
            ></textarea>
            <button
              data-aos="fade-right"
              type="submit"
              className="mt-5 text-raleway text-xl font-semibold text-white px-8 py-3 bg-[#2C0080] hover:bg-[#007AFF] rounded-md"
            >
              Get Consultation
            </button>
          </form>
          <div className="flex flex-col justify-center">
            <p
              data-aos="fade-right"
              className="text-primary font-monstserrat text-4xl font-bold"
            >
              Connect with Algorizm<span className="font-inter">i</span>
            </p>
            <p
              data-aos="fade-right"
              className="font-raleway lg:text-2xl text-xl font-normal text-gray-500 mt-5"
            >
              Ready to enhance your decision-making process? Discover the power
              of insightful surveys with Algorizmi. Reach out to us today and
              embark on a journey of informed product strategies.
            </p>
            <p
              data-aos="fade-right"
              className="text-[#4540c6] font-montserrat lg:text-base sm:text-sm text-xs font-semibold mt-5"
            >
              Algorizmi - Elevating Insights, Empowering Decisions
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
