import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay  } from "swiper/modules";
import React from "react";

const testimonials = [
  {
    id: 1,
    name: "Aisha Patel",
    description:
      "Algorizmi's AI solutions have been a game-changer for our e-commerce business in Africa. Their predictive analytics tools have provided valuable insights into consumer behavior, helping us tailor our marketing strategies effectively. The Algorizmi team's expertise and support have been invaluable in driving growth in our market. I highly recommend Algorizmi to businesses looking to succeed in the African market.",
  },
  {
    id: 2,
    name: "Diego Fernandez",
    description:
      "Algorizmi's AI-powered solutions have been instrumental in optimizing our supply chain operations in South America. Their logistics optimization tools have helped us streamline our processes, reduce costs, and improve delivery efficiency. The platform's intuitive interface and customizable features make it easy to implement and scale. Algorizmi has become a trusted partner in our journey towards operational excellence.",
  },
  {
    id: 3,
    name: "Priya Singh",
    description:
      "As a digital marketer in Asia, I've found Algorizmi's AI-driven insights to be incredibly valuable. Their personalized recommendations engine has helped us improve engagement and conversions across our campaigns. The platform's flexibility and adaptability have made it easy to integrate into our existing workflows. Algorizmi has truly revolutionized the way we approach digital marketing in the Asian market.",
  },
  {
    id: 4,
    name: "Carlos Gutierrez",
    description:
      "Algorizmi's AI solutions have been indispensable for our business in Europe. Their predictive analytics tools have helped us stay ahead of market trends and consumer preferences. The platform's user-friendly interface and comprehensive features make it a valuable asset for any organization. Working with Algorizmi has been a pleasure, and we look forward to continued success with their innovative solutions.",
  },
  {
    id: 5,
    name: "Mai Nguyen",
    description:
      "Algorizmi's AI platform has been a game-changer for our data analytics efforts in Oceania. Their machine learning algorithms have enabled us to extract valuable insights from our data, driving informed decision-making and business growth. The Algorizmi team's expertise and support have been invaluable throughout the process. I highly recommend Algorizmi to any organization looking to harness the power of AI for data-driven success.",
  },
];

const renderTestimonials = testimonials.map((item: any, index: number) => {
  return (
    <React.Fragment key={`${item.id}${index}`}>
      <SwiperSlide>
        <div className="border border-gray-300 rounded-lg">
          <div className="px-4 py-5 border-b border-gray-300">
            <div className="flex items-center relative w-full">
              <h2 className="font-raleway text-2xl font-bold text-primary">
                {item.name}
              </h2>
              <img
                src="/svgs/double-quotes.svg"
                alt=""
                className="w-6 absolute right-3"
              />
            </div>
          </div>
          <div className="px-4 py-5">
            <p>{item.description}</p>
          </div>
        </div>
      </SwiperSlide>
    </React.Fragment>
  );
});

const SuccessStories = () => {
  return (
    <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-16 xl:w-[85%] w-full">
      <div className="flex flex-col items-center">
        <p
          data-aos="fade-right"
          className="text-primary text-center font-montserrat lg:text-5xl sm:text-3xl text-base font-bold"
        >
          Hear Our Client’s Success Stories
        </p>
        <div
          data-aos="fade-right"
          className="mt-5 w-80 h-[8px] rounded-lg bg-[#F10062]"
        ></div>
      </div>
      <div data-aos="fade-right" className="mt-10">
        <Swiper
          navigation={true}
          modules={[Navigation, Autoplay]}
          className="mySwiper"
          slidesPerView={3}
          spaceBetween={30}
          breakpoints={{
            200: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          autoplay={{ delay: 5000 }}
        >
          {renderTestimonials}
        </Swiper>
      </div>
    </div>
  );
};

export default SuccessStories;
