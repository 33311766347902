import React, { useState } from "react";

const serviceData = [
  {
    id: 1,
    bgColor: "#CFEAFF",
    textColor: "#144C8C",
    serviceIcon: "/svgs/insights.svg",
    serviceName: "Actionable Insights",
    serviceDesc:
      "Translate data into action with insights that guide your strategic decisions",
  },
  {
    id: 2,
    bgColor: "#FFACD4",
    textColor: "#862353",
    serviceIcon: "/svgs/user-friendly.svg",
    serviceName: "User-Friendly Experience",
    serviceDesc:
      "Our simplified process ensures a positive experience for both administrators and respondents",
  },
  {
    id: 3,
    bgColor: "#155CAB",
    textColor: "#fff",
    serviceIcon: "/svgs/versatility.svg",
    serviceName: "Versatility",
    serviceDesc:
      "Adapt our survey solutions to fit your unique product requirements, from consumer engagement to product performance analysis",
  },
];

const OurServices = () => {
  const [hoverStates, setHoverStates] = useState<boolean[]>(
    new Array(serviceData.length).fill(false)
  );

  const handleMouseEnter = (index: number) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = true;
    setHoverStates(updatedHoverStates);
  };

  const handleMouseLeave = (index: number) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = false;
    setHoverStates(updatedHoverStates);
  };

  const renderServicesData = serviceData.map((item: any, index: number) => {
    return (
      <React.Fragment key={`${item.id}${index}`}>
        <div
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
          className={`border pt-12 rounded-[30px] relative transition-all ease-in-out duration-500 mt-14 ${
            hoverStates[index] ? "h-[19rem]" : "h-[17rem]"
          }`}
          style={{ backgroundColor: item.bgColor }}
        >
          <div className="absolute -top-12 right-[39%] bg-white w-24 h-24 rounded-full flex items-center justify-center">
            <img src={item.serviceIcon} alt="" className="w-16" />
          </div>
          <div className="lg:px-5 px-3 mt-6">
            <h3
              className={`font-raleway font-bold lg:text-2xl text-xl text-center`}
              style={{ color: item.textColor }}
            >
              {item.serviceName}
            </h3>
            <p
              className="font-raleway lg:text-lg text-base font-medium text-center mt-3"
              style={{ color: item.textColor }}
            >
              {item.serviceDesc}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  });
  return (
    // <div className="services-bg">
    <div>
      <div
        id="services"
        className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-20 xl:w-[85%]"
      >
        <div className="flex flex-col items-center">
          <p data-aos="fade-down" className="text-primary text-center font-montserrat md:text-2xl text-base font-semibold tracking-[6.6px]">
            Our Services
          </p>
          <h2 data-aos="fade-down" className="font-raleway lg:text-5xl sm:text-3xl text-2xl font-bold mt-5 text-center">
            Why Choose Algorizmi?
          </h2>
          <div
            data-aos="fade-right"
            className="mt-5 w-80 h-[8px] rounded-lg bg-[#F10062]"
          ></div>
        </div>

        <div data-aos="fade-up" className="lg:mt-20 mt-10 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-8 gap-5">
          {renderServicesData}
        </div>
      </div>
    </div>
  );
};

export default OurServices;
