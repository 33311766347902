import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import * as Icons from "../Common/Icons";

const Philosophy = () => {
  return (
    <>
      <div className="w-full philosophyg bg-[#F6F6F7] bg-cover bg-center">
        <div
          id="philosophy"
          className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-16 xl:w-[85%] w-full relative"
        >
          <div className="flex flex-col items-center">
            <p
              data-aos="fade-right"
              className="text-primary text-center font-montserrat lg:text-5xl md:text-3xl text-base font-bold"
            >
              Join Industry Leaders Growing <br /> Their Business with Algorizmi
            </p>
            <div
              data-aos="fade-right"
              className="mt-5 w-80 h-[8px] rounded-lg bg-[#F10062]"
            ></div>
          </div>
          <div
            data-aos="fade-right"
            className="flex items-center justify-end gap-5 pr-5 lg:mt-0 mt-7"
          >
            <div className="swiper-button-prev cursor-pointer text-primary">
              <Icons.LeftArrowIcon />
            </div>
            <div className="swiper-button-next cursor-pointer text-primary">
              <Icons.RightArrowIcon />
            </div>
          </div>
          <Swiper
            data-aos="fade-up"
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper mt-6"
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
          >
            <SwiperSlide>
              <div className="md:h-52 md:py-0 py-10 w-full border-2 border-primary flex flex-col items-center justify-center rounded-xl px-10">
                <p className="font-montserrat text-2xl font-bold text-center text-primary">
                  Understanding Your Audience
                </p>
                <p className="font-montserrat text-lg font-semibold text-center mt-4 pb-6">
                  In the dynamic landscape of today's markets, understanding
                  your audience is key. Algorizmi helps you connect with your
                  target demographic, exploring their preferences, opinions, and
                  behaviors.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="md:h-52 md:py-0 py-10 w-full border-2 border-primary flex flex-col items-center justify-center rounded-xl px-10">
                <p className="font-montserrat text-2xl font-bold text-center text-primary">
                  Data-Driven Decision-Making
                </p>
                <p className="font-montserrat text-lg font-semibold text-center mt-4 pb-6">
                  Harness the power of data without the complexity. Our surveys
                  are designed to provide you with a clear understanding of
                  market trends, enabling you to make informed decisions that
                  drive success.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="md:h-52 md:py-0 py-10 w-full border-2 border-primary flex flex-col items-center justify-center rounded-xl px-10">
                <p className="font-montserrat text-2xl font-bold text-center text-primary">
                  Customized Solutions, No Complexity
                </p>
                <p className="font-montserrat text-lg font-semibold text-center mt-4 pb-6">
                  Tailoring our approach to your needs, Algorizmi ensures a
                  seamless and straightforward experience. No unnecessary
                  complexities, just valuable insights to guide your business
                  strategy.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="md:h-52 md:py-0 py-10 w-full border-2 border-primary flex flex-col items-center justify-center rounded-xl px-10">
                <p className="font-montserrat text-2xl font-bold text-center text-primary">
                  Product-focused Expertise
                </p>
                <p className="font-montserrat text-lg font-semibold text-center mt-4 pb-6">
                  Whether you have a single product or a diverse product line,
                  Algorizmi offers survey solutions that delve into consumer
                  perceptions, helping you optimize your offerings for maximum
                  impact.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <img src="/svgs/background.svg" alt="" className="w-full" />
    </>
  );
};

export default Philosophy;
