import { useState, useEffect } from "react";
import { CrossIcon, HamburgerIcon } from "../Common/Icons";

const Header = () => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  useEffect(() => {
    let prevScroll = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const navbar = document.getElementById("navbar");

      if (!navbar) return;

      if (document.documentElement.scrollTop < 10) {
        navbar.style.backgroundColor = "transparent";
      } else {
        navbar.style.backgroundColor = "#171E26";
      }

      if (prevScroll > currentScrollPos) {
        navbar.style.top = "0";
      } else {
        navbar.style.top = "-150px";
        navbar.style.transition = "0.5s";
      }

      prevScroll = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header id="navbar" className="w-full fixed top-0 z-40">
      <div className="w-ful bg-transparent">
        {/* Desktop View */}
        <div className="2xl:container mx-auto py-4 lg:px-16 px-5 h-full lg:flex hidden items-center justify-between">
          <a href="/">
            <img src="logo.png" alt="" className="w-16" />
          </a>
          <nav  className="flex items-center h-full lg:gap-8 gap-5">
            <a href="#home" className="nav-items">
              Home
            </a>
            <a href="#philosophy" className="nav-items">
              Philosophy
            </a>
            <a href="#services" className="nav-items">
              Services
            </a>
            <a href="#our-clients" className="nav-items">
              Our Clients
            </a>
            <a href="#contact" className="nav-items">
              Contact
            </a>
          </nav>
        </div>
        {/* Mobile View */}
        <div className="lg:hidden flex items-center justify-between sm:px-10 px-5 py-4 h-full">
          <a href="/">
            <img src="logo.png" alt="" className="w-14" />
          </a>
          <div
            className="text-white fill-current"
            onClick={() => setShowSidebar(true)}
          >
            <HamburgerIcon />
          </div>
          <div
            className={`fixed top-0 sm:w-2/5 w-4/5 ${
              showSidebar ? "right-0" : "-right-[100%]"
            } overflow-x-hidden transition-all ease-in-out duration-500 h-screen bg-primary z-50 shadow-xl shadow-black`}
          >
            <div className="w-full h-full relative">
              <div
                onClick={() => setShowSidebar(false)}
                className="absolute top-5 right-5 text-white fill-current"
              >
                <CrossIcon />
              </div>
              <nav className="flex flex-col justify-center items-center h-full lg:gap-8 gap-10">
                <a
                  onClick={() => setShowSidebar(false)}
                  href="/"
                  className="nav-items-mob"
                >
                  Home
                </a>
                <a
                  onClick={() => setShowSidebar(false)}
                  href="#philosophy"
                  className="nav-items-mob"
                >
                  Philosophy
                </a>
                <a
                  onClick={() => setShowSidebar(false)}
                  href="#services"
                  className="nav-items-mob"
                >
                  Services
                </a>
                <a
                  onClick={() => setShowSidebar(false)}
                  href="#our-clients"
                  className="nav-items-mob"
                >
                  Our Clients
                </a>
                <a
                  onClick={() => setShowSidebar(false)}
                  href="#contact"
                  className="nav-items-mob"
                >
                  Contact
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
