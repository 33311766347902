import React, { useState, useEffect } from 'react';

interface TypewriterProps {
  textArray: string[];
  period?: number;
  delay?: number;
}

const Typewriter: React.FC<TypewriterProps> = ({ textArray, period = 100, delay = 5000 }) => {
  const [currentText, setCurrentText] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [loopNum, setLoopNum] = useState<number>(0);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  useEffect(() => {
    const handleTyping = () => {
      const i: number = loopNum % textArray.length;
      const fullTxt: string = textArray[i];

      setCurrentText(prevText => {
        return isDeleting
          ? fullTxt.substring(0, prevText.length - 1)
          : fullTxt.substring(0, prevText.length + 1);
      });

      if (!isDeleting && currentText === fullTxt) {
        setIsDeleting(true);
      } else if (isDeleting && currentText === '') {
        setIsDeleting(false);
        setLoopNum(prevLoopNum => prevLoopNum + 1);
        setTimeout(() => setIsComplete(true), delay); // Set completion flag after delay
      }
    };

    const timeoutId: NodeJS.Timeout = setTimeout(handleTyping, period);

    return () => clearTimeout(timeoutId);
  }, [currentText, isDeleting, loopNum, period, textArray, delay]);

  return (
    <div className="typewriter-container">
      <span>{currentText}</span>
      {!isComplete && <span className="cursor-animation"></span>} {/* Animated cursor */}
    </div>
  );
};

export default Typewriter;
