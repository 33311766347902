import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Achievement = () => {
  const [visible, setVisible] = useState(false);
  const handleVisibilityChange = (isVisible: any, setVisibility: any) => {
    if (isVisible) {
      setVisibility(true);
    }
  };
  return (
    <div id="our-clients" className="achievement-bg">
      <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 xl:w-[85%] py-20 grid grid-cols-3 gap-10 justify-items-center">
        <div
          data-aos="fade-right"
          className="number flex flex-col justify-center items-center"
        >
          <img
            src="/images/achievement/img-1.png"
            alt=""
            className="lg:w-32 sm:w-20 w-12"
          />
          <VisibilitySensor
            onChange={(isVisible: any) =>
              handleVisibilityChange(isVisible, setVisible)
            }
            partialVisibility={true}
            offset={{ top: 100 }}
          >
            <CountUp
              start={0}
              end={visible ? 50 : 0}
              duration={3}
              className="counter font-raleway lg:text-6xl md:text-5xl text-3xl font-bold text-white mt-5"
            />
          </VisibilitySensor>
          <p className="font-raleway lg:text-2xl md:text-xl sm:text-lg text-base font-bold text-white text-center mt-3">
            Delighted Clients
          </p>
        </div>
        <div
          data-aos="fade-up"
          className="number flex flex-col justify-center items-center"
        >
          <img
            src="/images/achievement/img-2.png"
            alt=""
            className="lg:w-32 sm:w-20 w-12"
          />
          <div className="flex items-center mt-5">
            <VisibilitySensor
              onChange={(isVisible: any) =>
                handleVisibilityChange(isVisible, setVisible)
              }
              partialVisibility={true}
              offset={{ top: 100 }}
            >
              <CountUp
                start={0}
                end={visible ? 50 : 0}
                duration={3}
                className="counter font-raleway lg:text-6xl md:text-5xl text-3xl font-bold text-white"
              />
            </VisibilitySensor>
            <p className="font-raleway lg:text-6xl md:text-5xl text-3xl font-bold text-white sm:mt-1">
              K+
            </p>
          </div>
          <p className="font-raleway lg:text-2xl md:text-xl sm:text-lg text-base font-bold text-white text-center mt-3">
            App Users
          </p>
        </div>
        <div
          data-aos="fade-right"
          className="number flex flex-col justify-center items-center"
        >
          <img
            src="/images/achievement/img-3.png"
            alt=""
            className="lg:w-32 sm:w-20 w-12"
          />
          <div className="flex items-center mt-5">
            <VisibilitySensor
              onChange={(isVisible: any) =>
                handleVisibilityChange(isVisible, setVisible)
              }
              partialVisibility={true}
              offset={{ top: 100 }}
            >
              <CountUp
                start={0}
                end={visible ? 500 : 0}
                duration={3}
                className="counter font-raleway lg:text-6xl md:text-5xl text-3xl font-bold text-white"
              />
            </VisibilitySensor>
            <p className="font-raleway lg:text-6xl md:text-5xl text-3xl font-bold text-white sm:mt-1">
              K+
            </p>
          </div>
          <p className="font-raleway lg:text-2xl md:text-xl sm:text-lg text-base font-bold text-white text-center mt-3">
            Touch Points
          </p>
        </div>
      </div>
    </div>
  );
};

export default Achievement;
